import axios from 'axios'
import { 
    PLATE_DETAILS_REQUEST,
    PLATE_DETAILS_SUCCESS,
    PLATE_DETAILS_FAIL,
    PLATE_REACT_REQUEST,
    PLATE_REACT_SUCCESS,
    PLATE_REACT_FAIL,
    PLATE_COMMENT_REQUEST,
    PLATE_COMMENT_SUCCESS,
    PLATE_COMMENT_FAIL
} from "../constants/plateConstants";
import { getFireauthToken } from "../utils/fireauth"
import {
    BASE_URL
} from "../constants/config"

export const getPlateDetails = (type, plateNum, user) => async (dispatch) => {
    try {
        dispatch({
            type: PLATE_DETAILS_REQUEST,
        })

        let config = {}
        if (user) {
            const token = await getFireauthToken()
            config.headers = {
                Authorization: `Bearer ${token}`,
            }
        }

        const {data} = await axios.get(`${BASE_URL}/api/plate/${type}/${plateNum}`, config)

        dispatch({
            type: PLATE_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        if (error.response.data.code === 'PLATE_NOT_FOUND') {
            // Extract component 
            const data = {
                like: 0,
                commentNum: 0,
                monkey: 0,
                plateNum: plateNum,
                plateType: type,
                treasures: 0,
                turtle: 0,
                comments: []
            }
            dispatch({
                type: PLATE_DETAILS_SUCCESS,
                payload: data,
            })
        } else {
            dispatch({
                type: PLATE_DETAILS_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }
}

export const createPlateReaction = (plateId, plateType, plateNum, type) => async (dispatch) => {
    try {
        dispatch({
            type: PLATE_REACT_REQUEST,
        })

        const token = await getFireauthToken()

        // Set the header
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }

        let url, reactionData
        let body = {
            type: type,
            plateType: plateType,
            plateNum: plateNum,
        }
        if (plateId) {
            url = `${BASE_URL}/api/plate/${plateId}/reaction`
        } else {
            url = `${BASE_URL}/api/plate/reaction`
        }

        if (type != null) {
            // console.log("Post to ", url)
            // console.log("Body", body)
            // console.log("config", config)
            const { data } = await axios.post(url, body, config)
            reactionData = data
        } else {
            // console.log("Delete to ", url)
            // console.log("Body", body)
            // console.log("config", config)
            const { data } = await axios.delete(url, { 
                data: body, 
                headers: config.headers 
            });
            reactionData = data
        }

        dispatch({
            type: PLATE_REACT_SUCCESS,
            payload: reactionData,
        })
    } catch (error) {
        console.log(error)
        dispatch({
            type: PLATE_REACT_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createPlateComment = (plateId, plateType, plateNum, content) => async (dispatch) => {
    try {
        dispatch({
            type: PLATE_COMMENT_REQUEST,
        })

        const token = await getFireauthToken()

        // Set the header
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }

        let url
        let body = {
            content: content,
            plateType: plateType,
            plateNum: plateNum,
        }
        if (plateId) {
            url = `${BASE_URL}/api/plate/${plateId}/comment`
        } else {
            url = `${BASE_URL}/api/plate/comment`
        }

        console.log("Delete to ", url)
        console.log("Body", body)
        console.log("config", config)
        const { data } = await axios.post(url, body, config)
        // const data = {
        //     fromUid: "hC94GpQC8tamyGSnU2o68QrFmZI2",
        //     fromUsername: "an6us",
        //     toPlateId: "oXIyzQmJ03gV6aziywKS",
        //     content: "Test comment",
        //     timestamp: 1676711202
        // }

        await new Promise(resolve => setTimeout(resolve, 1000));

        dispatch({
            type: PLATE_COMMENT_SUCCESS,
            payload: data,
        })
    } catch (error) {
        console.log(error)
        dispatch({
            type: PLATE_COMMENT_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }  
}