import { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Header from '../components/header';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PlateReactionDashboard from '../components/PlateReactionDashboard';
import PlateCommentList from '../components/PlateCommentList';
import FullScreenLoader from '../components/FullScreenLoader';
import PlateTitle from '../components/PlateTitle';
import PlateMockup from '../components/PlateMockup';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../App';
import { 
    CarInput,
} from '../theme/plateStyle';
import { 
    getPlateDetails,
    createPlateReaction,
    createPlateComment
} from '../actions/plateActions';

const CommentField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 20,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    width: '85vw',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `1em`,
        transition: theme.transitions.create('width'),
        width: '75vw',
    },
}));

const PlateScreen = () => {

    const { authUser, authLoading, authError } = useContext(AuthContext);
    const {plateType, plateNum} = useParams()
    const navigate = useNavigate()
    const [alignment, setAlignment] = useState('web')
    const [plateString, setPlateString] = useState('')
    const [likeNum, setLikeNum] = useState(0)
    const [treasuresNum, setTreasuresNum] = useState(0)
    const [noiseNum, setNoiseNum] = useState(0)
    const [turtleNum, setTurtleNum] = useState(0)
    const [monkeyNum, setMonkeyNum] = useState(0)
    const [plateComments, setPlateComments] = useState([])
    const [selfReaction, setSelfReaction] = useState()
    const [selfComment, setSelfComment] = useState()
    const [commentContent, setCommentContent] = useState('')
    const dispatch = useDispatch()
    const plateDetails = useSelector((state) => state.plateDetails)
    const { loading, error, plate } = plateDetails
    const plateReact = useSelector((state) => state.plateReact)
    const { reactLoading, reactError, reaction } = plateReact
    const plateComment = useSelector((state) => state.plateComment)
    const { commentLoading, commentError, comment } = plateComment

    const handleChange = (
        event,
        newAlignment,
    ) => {
        setAlignment(newAlignment)
        if (selfReaction && newAlignment != selfReaction.type) {
            unreact(selfReaction.type)
        }
        createReaction(newAlignment)
    }

    const checkIfLogin = () => {
        if (authUser && !authLoading) {
            return true
        } else {
            return false
        }
    }
        
    const createReaction = (type) => {
        if (!checkIfLogin()) {
            navigate(`/login?redirect=/${plateType}/${plateNum}`)
            return
        }

        if (type) {
            dispatch(createPlateReaction(plate.id, plate.plateType, plate.plateNum, type))
        }
        switch (type) {
            case 'like': 
                setLikeNum(likeNum + 1)
                setSelfReaction({type: 'like'})
                break
            case 'treasures':
                setTreasuresNum(treasuresNum + 1)
                setSelfReaction({type: 'treasures'})
                break
            case 'turtle': 
                setTurtleNum(turtleNum + 1)
                setSelfReaction({type: 'turtle'})
                break
            case 'monkey': 
                setMonkeyNum(monkeyNum + 1)
                setSelfReaction({type: 'monkey'})
                break
            default:
                break
        }
    }

    const unreact = (type) => {
        if (!checkIfLogin()) {
            navigate(`/login?redirect=/${type}/${plateNum}`)
        }

        setSelfReaction()
        dispatch(createPlateReaction(plate.id, plate.plateType, plate.plateNum, null))
        switch (type) {
            case 'like': 
                setLikeNum(likeNum - 1)
                break
            case 'treasures':
                setTreasuresNum(treasuresNum - 1)
                break
            case 'turtle': 
                setTurtleNum(turtleNum - 1)
                break
            case 'monkey': 
                setMonkeyNum(monkeyNum - 1)
                break
            default:
                break
        }
    }

    const handleCommentFieldOnFocus = () => {
        if (!checkIfLogin()) {
            navigate(`/login?redirect=/${plateType}/${plateNum}`)
        }
    }

    const handleCommentContentChange = (e) => {
        setCommentContent(e.target.value)
    }

    const handleCommentSubmitButton = () => {
        console.log('Button click, submit', commentContent)
        dispatch(createPlateComment(plate.id, plate.plateType, plate.plateNum, commentContent))
    }

    const setPlateInfo = () => {
        if (!plate) {
            return 
        }
        setPlateString(plate.plateNum)
        setLikeNum(plate.like)
        setTreasuresNum(plate.treasures)
        setTurtleNum(plate.turtle)
        setMonkeyNum(plate.monkey)
        setPlateComments(plate.comments)
        if (plate.selfReaction) {
            setSelfReaction(plate.selfReaction)
            setAlignment(plate.selfReaction.type)
        } else {
            setSelfReaction()
            setAlignment()
        }
        if (plate.selfComment) {
            setSelfComment(plate.selfComment)
        } else {
            setSelfComment()
        }
    }

    const dispatchPlateDetails = () => {
        if (authUser) {
            dispatch(getPlateDetails(plateType, plateNum, true))
        } else {
            dispatch(getPlateDetails(plateType, plateNum, false))
        }
    }

    useEffect(() => {
        if (!plate && !authLoading) {
            dispatchPlateDetails()
        } 
    }, [plate, authLoading])

    useEffect(() => {
        if (plate && plate.plateNum != plateString.toLowerCase()) {
            dispatchPlateDetails()
        }
    }, [])

    useEffect(() => {
        if (plate) {
            setPlateInfo()
        }  
    }, [plate])

    useEffect(() => {
        if (comment) {
            setSelfComment(comment)
            setPlateComments([...plateComments, comment])
        }
    }, [comment])

    return (
        <>
            <Header arrow={true}/>
            {loading ? (
                <FullScreenLoader />
            ) : (
                <>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        style={{ minHeight: '100vh'}}
                        p={1}
                        pt={7}
                    >
                        <Grid
                            item
                            py={2}
                            sx={{ display: "flex", justifyContent: "flex-start" }}
                        >
                            <PlateTitle type={plateType} />
                        </Grid>
                        <Grid
                            item
                            sx={{ display: "flex", justifyContent: "center" }}
                        >
                            <PlateMockup type={plateType} plateNum={plateNum} />
                        </Grid>
                        <Grid
                            item
                            px={2}
                            py={4}
                            sx={{ display: "flex", justifyContent: "center" }}
                        >
                            <PlateReactionDashboard 
                                like={likeNum}
                                treasures={treasuresNum}
                                turtle={turtleNum}
                                monkey={monkeyNum}
                            />
                        </Grid>
                        <Grid
                            item
                            sx={{ display: "flex", justifyContent: "center" }}
                        >
                            {/* Extract component  */}
                            <ToggleButtonGroup
                                color="secondary"
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                                sx={{
                                    [`& .MuiToggleButtonGroup-root`]: {
                                        '& .MuiToggleButton-primary': { backgroundColor: 'blue' },
                                    },
                                }}
                            >
                                <ToggleButton 
                                    value="like" 
                                    variant="contained"
                                    sx={{
                                        minWidth: '20vw',
                                    }} 
                                >
                                    <Box
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Typography variant="caption" sx={{fontWeight: 'bold'}}>
                                            👍 讚
                                        </Typography>
                                    </Box>
                                </ToggleButton>
                                <ToggleButton value="treasures" sx={{minWidth: '20vw'}}>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Typography variant="caption" sx={{fontWeight: 'bold'}}>
                                            🍗 三寶
                                        </Typography>
                                    </Box>
                                </ToggleButton>
                                <ToggleButton value="turtle" sx={{minWidth: '20vw'}}>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Typography variant="caption" sx={{fontWeight: 'bold'}}>
                                            🐢 烏龜
                                        </Typography>
                                    </Box>
                                </ToggleButton>
                                <ToggleButton value="monkey" sx={{minWidth: '20vw'}}>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Typography variant="caption" sx={{fontWeight: 'bold'}}>
                                            🐒 猴
                                        </Typography>
                                    </Box>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid
                            item
                            sx={{ display: "flex", justifyContent: "flex-start" }}
                        >
                            <PlateCommentList comments={plateComments} />
                        </Grid>
                    </Grid>
                    <AppBar 
                        position="fixed" 
                        sx={{ 
                            top: 'auto', 
                            bottom: 0,
                        }}
                    >
                        <Toolbar sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}>
                            { selfComment ? (
                                <Typography>
                                    已留言
                                </Typography> 
                            ) : commentLoading ? ( 
                                <CircularProgress color="secondary" size={30}/> 
                            ) : (
                                <>
                                    <CommentField>
                                        <StyledInputBase
                                            placeholder="輸入留言..."
                                            onFocus={handleCommentFieldOnFocus}
                                            inputProps={{ "aria-label": "search" }}
                                            onChange={handleCommentContentChange}
                                            value={commentContent}
                                        />
                                    </CommentField>
                                    <IconButton 
                                        onClick={handleCommentSubmitButton}
                                        color='inherit'
                                    >
                                        <ArrowUpwardIcon />
                                    </IconButton>
                                </>
                            )}
                        </Toolbar>
                    </AppBar>  
                </>
            )}
        </>
    )
}

export default PlateScreen  