import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import SwipeableViews from 'react-swipeable-views';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MopedIcon from '@mui/icons-material/Moped';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import { 
    CarInput,
    BusinessInput,
    RedMotoInput,
    YellowMotoInput
} from '../theme/plateStyle';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box 
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                py={5}
            >
                {children}
            </Box>
        )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const PlateInputSwiper = ({tabValue, setTabValue, plate, setPlate, error, handleSearch}) => {

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setTabValue(index);
    };

    const handlePlateChange = (e) => {
        if (e.target.value.length > 8) {
            return
        }
        e.target.value = e.target.value.toUpperCase()
        setPlate(e.target.value)
        // 車號小於兩碼不需要檢查 
        if (e.target.value.length <= 1) {
            return 
        }
        // Re test
        const privateCarPlateRe1 = /^([A-Z][0-9])$/g.test(e.target.value)
        const privateCarPlateRe2 = /^([0-9][A-Z])$/g.test(e.target.value)
        const plate4NumRe = /^([0-9]{4})$/g.test(e.target.value)
        const plate3CharRe = /^([A-Z]{3})$/g.test(e.target.value)
        // Handle deletion
        const prevIncludeHyphen = plate.includes('-')
        if ((privateCarPlateRe1 || privateCarPlateRe2 || plate4NumRe || plate3CharRe) && !prevIncludeHyphen) {
            e.target.value =  e.target.value + "-"
            setPlate(e.target.value)
        }
        let currentIndex = e.target.value.length - 1
        let prevCharAscii = e.target.value.charCodeAt(currentIndex-1)
        let currentCharAscii = e.target.value.charCodeAt(currentIndex)
        // Check the coversion between number and character
        if (checkType(prevCharAscii) === checkType(currentCharAscii) || 
            checkType(currentCharAscii) === 'Hyphen' || 
            checkType(prevCharAscii) === 'Hyphen' ||
            prevIncludeHyphen) {
            return
        } else {
            const plateStr = e.target.value
            e.target.value = plateStr.slice(0, currentIndex) + "-" + plateStr.slice(currentIndex)
            setPlate(e.target.value)
        }
    }

    const handleKeyPress = (e) => {
        if(e.keyCode == 13){
            handleSearch()
        }
    }

    const checkType = (c) => {
        if ( c >= 65 && c <= 90) {
            return 'UppercaseChar'
        } else if (c >= 48 && c <= 57) {
            return 'Number'
        } else if (c === 45) {
            return 'Hyphen'
        } else {
            return 'Unknown'
        }
    }

    const HelperText = ({error}) => {
        return (
            <>
                { error ? (
                    <FormHelperText 
                        id="my-helper-text" 
                        error={true}
                        sx={{
                            paddingTop: '10px',
                            textAlign: 'center',
                        }}
                    >
                        車牌格式錯誤
                    </FormHelperText> 
                ) : (
                    <FormHelperText 
                        id="my-helper-text" 
                        sx={{
                            paddingTop: '10px',
                            textAlign: 'center',
                            color: 'primary'
                        }}
                    >
                        請輸入車牌
                    </FormHelperText>
                )}
            </>
        )
    }

    return (
        <Grid
            container
            spacing={0}
            justifyContent={'center'}
            justifyItems={'center'}
        >
            <Box
                sx={{
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={true}
                    textColor="secondary"
                    indicatorColor="secondary"
                    allowScrollButtonsMobile
                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                    }}
                >
                    <Tab 
                        icon={<DirectionsCarIcon />} 
                        label="自用小客車" 
                        {...a11yProps(0)}
                    />
                    <Tab 
                        icon={<MopedIcon />} 
                        label="普通重型機車" 
                        {...a11yProps(1)} 
                    />
                    <Tab 
                        icon={<LocalTaxiIcon />} 
                        label="營業小客車" 
                        {...a11yProps(2)}
                     />
                    <Tab 
                        icon={<TwoWheelerIcon />} 
                        label="紅牌" 
                        {...a11yProps(3)} 
                    />
                    <Tab 
                        icon={<TwoWheelerIcon />} 
                        label="黃牌" 
                        {...a11yProps(4)} 
                    />
                </Tabs>
            </Box>
            <SwipeableViews
                index={tabValue}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={tabValue} index={0}>
                    <FormControl>
                        <CarInput 
                            placeholder="AAA-0001"
                            value={plate}
                            onChange={handlePlateChange}
                            onKeyDown={handleKeyPress}
                            id="private-vehicle-input"
                        />
                        <HelperText error={error} />
                    </FormControl>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <FormControl>
                        <CarInput 
                            placeholder="AAA-0001"
                            value={plate}
                            onChange={handlePlateChange}
                            onKeyDown={handleKeyPress}
                            id="ordinary-heavy-moto-input"
                        />
                        <HelperText error={error} />
                    </FormControl>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <FormControl>
                        <BusinessInput 
                            placeholder="TAA-0001" 
                            id="operating-vehicle-input" 
                            value={plate}
                            onChange={handlePlateChange}
                            onKeyDown={handleKeyPress}
                        />
                        <HelperText error={error} />
                    </FormControl>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <FormControl>
                        <RedMotoInput 
                            placeholder="LGA-0001" 
                            id="bigsized-heavy-moto-red-input" 
                            value={plate}
                            onChange={handlePlateChange}
                            onKeyDown={handleKeyPress}
                        />
                        <HelperText error={error} />
                    </FormControl>
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                    <FormControl>
                        <YellowMotoInput
                            placeholder="LAA-0001" 
                            id="bigsized-heavy-moto-yellow-input" 
                            value={plate}
                            onChange={handlePlateChange}
                            onKeyDown={handleKeyPress}
                        />
                        <HelperText error={error} />
                    </FormControl>
                </TabPanel>
            </SwipeableViews>
        </Grid>
    )
}

export default PlateInputSwiper