import { useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import auth from '../firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';


const Header = ({arrow}) => {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth);

    const handleClickHomeButton = () => {
        navigate('/')
    }

    const handleClickAccountButton = () => {
        if (user) {
            navigate('/profile')
        } else {
            navigate('/login')
        }
    }

    const LeftIcon = () => {
        return (
            <>
                { arrow ? (
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleClickHomeButton}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                ) : (
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleClickHomeButton}
                    >
                        <HomeIcon />
                    </IconButton>
                )}
            </>
        )
    }

    return (
        <AppBar 
            position="fixed" 
        >
            <Toolbar>
                <LeftIcon />
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    whoscar
                </Typography>
                <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleClickAccountButton}
                >
                    <AccountCircleIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
} 

export default Header