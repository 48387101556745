import {
    FORM_SUBMIT_REQUEST,
    FORM_SUBMIT_SUCCESS,
    FORM_SUBMIT_FAIL
} from '../constants/scamConstants';
import axios from 'axios';
import {
    BASE_URL
 } from "../constants/config";

export const submitForm = (body) => async (dispatch) => {
    try {
        dispatch({
            type: FORM_SUBMIT_REQUEST,
        })

        const { data } = await axios.post(`${BASE_URL}/api/scam/submit`, body)

        dispatch({
            type: FORM_SUBMIT_SUCCESS,
        })
    } catch (error) {
        console.log(error)
        dispatch({
            type: FORM_SUBMIT_FAIL,
        })
    }
}