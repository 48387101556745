import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import auth from '../firebaseConfig';
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { register, getUserProfile } from '../actions/userActions';
import Format from '../utils/format';
import FullScreenLoader from '../components/FullScreenLoader';

const RegisterScreen = () => {

    const dispatch = useDispatch()
    const userRegister = useSelector((state) => state.userRegister)
    const { registerLoading, registerError, userInfo } = userRegister
    const userProfile = useSelector((state) => state.userProfile)
    const { profileLoading, profileError, profile } = userProfile
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth);
    const [username, setUsername] = useState("")
    const [helperText, setHelperText] = useState("")
    const [usernameError, setUsernameError] = useState(false)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const redirectUrl = searchParams.get('redirect')

    const handleRegister = () => {
        console.log(Format.username(username))
        if (!Format.username(username)) {
            setUsernameError(true)
            setHelperText("使用者名稱僅能包含小寫英文字母,數字,逗號以及底線")
        } else {
            dispatch(register(user.uid, username, user.providerData[0].providerId))
            setUsernameError(false)
            setHelperText("")
        }
    }

    // Handle user state and info 
    useEffect(() => {
        if (!user && !loading) {
            navigate('/login')
        } else if (user && !loading) {
            console.log('Dispatch check user info')
            dispatch(getUserProfile())
        }
    }, [user, loading])

    // Handle auto generate ID
    useEffect(() => {
        if (!loading && !profile) {
            const shortenId = user.uid.slice(0, 5).toLowerCase()
            const shortenTimestamp = new Date().getTime().toString().slice(10, 13)
            const autoGenerateId = `user_${shortenId}${shortenTimestamp}` 
            setUsername(autoGenerateId);
        }
    }, [loading, profile])

    // Handle already exist user 
    useEffect(() => {
        if (profile) {
            console.log('Profile exist')
            navigate('/')
        }
    }, [profile])

    // Handle register success
    useEffect(() => {
        if (userInfo) {
            const navigateUrl = redirectUrl ? redirectUrl : '/'
            navigate(navigateUrl)
        }
    }, [userInfo])

    // Handle repete username and register error
    useEffect(() => {
        if (registerError && registerError.code === 'USERNAME_TAKEN') {
            setUsernameError(true)
            setHelperText("使用者名稱已有人使用")
        }
    }, [registerError])

    if (loading) {
        console.log('loading', loading)
        return (
            <FullScreenLoader />
        )
    }

    return (
        <>
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <Grid
                            container
                            spacing={1}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            p={5}
                        >
                            <Grid item xs={12}>
                                <Typography variant="h5" fontWeight={600}>註冊</Typography>
                            </Grid>   
                            <Grid item xs={12}>
                                <Typography variant="caption" fontWeight={600}>輸入使用者名稱</Typography>
                            </Grid>   
                            <Grid item xs={3} pb={2}>
                                <TextField
                                    id="input-with-icon-textfield"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" variant='sizeSmall'>
                                                <AlternateEmailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    error={usernameError}
                                    helperText={helperText}
                                    sx={{width: '55vw'}}
                                    inputProps={{ style: { textTransform: "lowercase" } }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Button 
                                    variant="contained" 
                                    loading={true}
                                    onClick={handleRegister}
                                    style = {{width: '60vw'}}
                                >
                                    {registerLoading ? (
                                        <CircularProgress size={25} color="secondary"/> 
                                    ) : (
                                        "完成"
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid> 
        </>
    )
}

export default RegisterScreen