import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

const FullScreenLoader = () => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            style={{ minHeight: '100vh'}}
            justifyContent={'center'}
            alignItems={'center'}
            p={1}
            pt={7}
        >
            <CircularProgress color="secondary" />
        </Grid>
    )
}

export default FullScreenLoader