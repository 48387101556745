import auth from "../firebaseConfig";

const ifLogin = () => {
    if (auth.currentUser) {
        return true
    } else {
        return false
    }
}

const getFireauthToken = async () => {
    if (auth.currentUser) {
        return auth.currentUser.getIdToken(true)
    } else {
        return new Promise((resolve, reject) => {
            reject('Get fireauth token failed');
        });
    }
}

export {
    ifLogin,
    getFireauthToken
}