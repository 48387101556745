import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import FacebookIcon from '@mui/icons-material/Facebook';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Alert from '@mui/material/Alert';
import auth from '../firebaseConfig';
import { 
    signInWithPopup, 
    GoogleAuthProvider, 
    signOut, 
    FacebookAuthProvider 
} from "firebase/auth";
import {login} from '../actions/userActions';
import { useNavigate } from "react-router-dom";
import { getFireauthToken } from '../utils/fireauth';
import { useAuthState } from 'react-firebase-hooks/auth';
import Header from '../components/header';
import { useLocation } from 'react-router-dom';
import {
    HOSTING_URL
} from "../constants/config"
import FullScreenLoader from '../components/FullScreenLoader';

const LoginButtonStyle = {
    width: '75vw',
    paddingY: '0.5em'
}

function AppleLoginButton() {
    const [appleAuth, setAppleAuth] = useState(null);

    function handleSignInWithApple() {
        const { AppleID } = window;

        if (AppleID) {
            AppleID.auth.init({
                clientId: "your_client_id",
                scope: "email name",
                redirectURI: "https://yourapp.com/redirect",
                state: "your_state",
                usePopup: true,
            }).then((appleAuth) => {
                setAppleAuth(appleAuth);

                appleAuth.onSignIn((appleIdAuthResult) => {
                    const { authorization } = appleIdAuthResult;
                    const { code } = authorization;
                    // Send the authorization code to your server to exchange it for an access token
                    // Your server can then use the access token to authenticate the user
                });
            })
        }

        return (
            <button onClick={handleSignInWithApple}>
                Sign in with Apple
            </button>
        )
    }
}

const LoginScreen = () => {

    const dispatch = useDispatch()
    const userLogin = useSelector((state) => state.userLogin)
    const location = useLocation()
    const [user, loading, error] = useAuthState(auth);
    const { loginLoading, loginError, userInfo } = userLogin
    const searchParams = new URLSearchParams(location.search);
    const redirectUrl = searchParams.get('redirect');
    const [browserHelperText, setBrowserHelperText] = useState(false)

    const navigate = useNavigate()

    const constHandleUserAgent = () => {
        const thirdPartyBrowser = (
            navigator.userAgent.includes('Line') || 
            navigator.userAgent.includes('Instagram') ||  
            navigator.userAgent.includes('Messenger') 
        )
        if (thirdPartyBrowser) {
            setBrowserHelperText(true)
        } else {
            setBrowserHelperText(false)
        }
    }

    const handleGoogleLogin = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                dispatch(login())
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
            });
    }

    const handleFacebookLogin = () => {
        const provider = new FacebookAuthProvider()
        signInWithPopup(auth, provider)
            .then((result) => {
                console.log(result)
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
            });
    }

    const handleGetToken = async() => {
        const token = await getFireauthToken()
        console.log(token)
    }

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate('/')
        }).catch((error) => {
            // An error happened.
        });
    }

    // Handle login 
    useEffect(() => {
        if (user && !loading) {
            dispatch(login())
        }
    }, [user])

    // Handle login success
    useEffect(() => {
        if (userInfo && !loading) {
            if (userInfo.firstTime) {
                const registerUrl = redirectUrl ? `/register?redirect=${redirectUrl}` : '/register'
                navigate(registerUrl)
            } else {
                const navigateUrl = redirectUrl ? redirectUrl : '/'
                navigate(navigateUrl)
            }
        } 
    }, [ userInfo ])

    // Check if embed browser
    useEffect(() => {
        constHandleUserAgent()
    }, [])

    return (
        <>
            { loginLoading ? (
                <>
                    <FullScreenLoader />
                </>
            ) : (
                <>
                    <Header />
                    <Grid
                        container
                        spacing={3}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ minHeight: '100vh' }}
                    >   
                        
                        <Grid 
                            item 
                            xs={12} 
                            sx={{ display: "flex", justifyContent: "flex-start" }}
                        >
                            <Typography 
                                variant="h3" 
                                color={'primary.main'} 
                                fontWeight={600}
                                sx={{
                                    letterSpacing: '-2px'
                                }}
                            >
                                whoscar
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                <Grid
                                    container
                                    spacing={3}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    padding={3}
                                >   
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                        <Typography variant="h5" fontWeight={600}>登入</Typography>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <Button 
                                            variant="contained" 
                                            startIcon={<AppleIcon />}
                                            sx={LoginButtonStyle}
                                            onClick={handleLogout}
                                        >
                                            Login with Apple
                                        </Button>
                                    </Grid>    */}
                                    <Grid item xs={12}>
                                        <Button 
                                            variant="contained" 
                                            startIcon={<GoogleIcon />}
                                            sx={LoginButtonStyle}
                                            onClick={handleGoogleLogin}
                                        >
                                            使用Google繼續
                                        </Button>
                                    </Grid>   
                                    {/* <Grid item xs={12}>
                                        <Button 
                                            variant="contained" 
                                            startIcon={<FacebookIcon />}
                                            sx={LoginButtonStyle}
                                            onClick={handleFacebookLogin}
                                        >
                                            使用Facebook繼續
                                        </Button>
                                    </Grid>    */}
                                </Grid>
                            </Card>
                        </Grid>
                        {browserHelperText && (
                            <Grid item xs={12}>
                                    <Alert severity="warning" sx={LoginButtonStyle}>
                                        <Grid
                                            container
                                            spacing={1}
                                            direction="column"
                                        >
                                            <Grid item xs={12}>
                                                <Typography 
                                                    variant="body"  
                                                    fontWeight={400}
                                                >
                                                    系統偵測目前使用app內建瀏覽器，請切換成預設瀏覽器，以免造成登入失敗。
                                                </Typography>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <Button 
                                                    variant="outlined" 
                                                    startIcon={<OpenInNewIcon />}
                                                    onClick={handleOpenBrowserButton}
                                                >
                                                    <Typography 
                                                        variant="caption"  
                                                        fontWeight={400}
                                                    >
                                                        打開瀏覽器
                                                    </Typography>
                                                </Button>
                                            </Grid> */}
                                        </Grid>
                                    </Alert>
                            </Grid>
                        )}
                    </Grid> 
                </>
            )}
        </>
    )
}

export default LoginScreen