import { createContext } from "react";
import { Route, Routes } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import ProfileScreen from "./screens/ProfileScreen";
import RegisterScreen from "./screens/RegisterScreen";
import TestIpScreen from "./screens/TestIpScreen";
import PlateScreen from "./screens/PlateScreen";
import RiaScreen from "./screens/RiaScreen";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from './firebaseConfig';
export const AuthContext = createContext({});

function App() {
  const [user, loading, error] = useAuthState(auth);

  return (
    <AuthContext.Provider 
      value={{ 
        authUser: user, 
        authLoading: loading, 
        authError: error 
      }}
    >
      <Routes>
        <Route path='/' element={<HomeScreen />} exact/>
        <Route path='/profile' element={<ProfileScreen />} exact/> 
        <Route path='/:plateType/:plateNum' element={<PlateScreen />} exact/> 
        <Route path='/register' element={<RegisterScreen />} />
        <Route path='/login' element={<LoginScreen />}/>
        <Route path='/testip' element={<TestIpScreen />}/>
        <Route path='/ria' element={<RiaScreen />}/>
      </Routes>
    </AuthContext.Provider>
  );
}

export default App;
