import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField, Typography, Box, Card, CardContent, CircularProgress, Link, LinearProgress } from '@mui/material';
import backgroundImage from '../assets/riaBackground.jpg';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { submitForm } from '../actions/scamActions';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#C0C0C0', // 設定主要顏色
    },
    secondary: {
      main: '#003060', // 設定次要顏色
    },
    text: {
      primary: '#C0C0C0', // 設定主要文字顏色為白色
      secondary: '#C0C0C0', // 設定次要文字顏色為白色（可選）
    },
    shape: {
      borderRadius: 10,
    },
  },
});

export default function RiaScreen() {
  const dispatch = useDispatch();
  const scamSubmit = useSelector((state) => state.scam);
  const { loading, success, error } = scamSubmit;

  const [formData, setFormData] = useState({
    entertainmentURL: '',
    account: '',
    phoneSerial: '',
    ipAddress: '',
    vendorURL: '',
    amount: '',
    unopenedFreeCount: '',
    previousOne: '',
    previousTwo: '',
    days30: '',
    pointRate: ''
  });
  const [result, setResult] = useState(null);

  useEffect(() => {

    if (success) {
      // Reset the form or show success message
      setResult({
        ...formData,
        percentage: (Math.random() * 10).toFixed(2),
        formerSpin: Math.floor(Math.random() * 17) + 1,
        postSpin: Math.floor(Math.random() * 16) + 5,
        lastSpin: Math.floor(Math.random() * 11) + 10
      });
    }
  }, [success, formData]);

  // Handler for form input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Handler for form submission
  const handleSubmit = () => {
    dispatch(submitForm(formData));
  };

  // Logic for showing either the form or the results page
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    padding: '20px',
    flexDirection: 'column',
  };

  const cardStyle = {
    width: '100%',
    maxWidth: '500px', // Limit the maximum width
    backgroundColor: 'rgba(255, 255, 255, 0.3)', // 設置卡片透明度
    boxShadow: 3,
    marginTop: '20px', // Add some space between the title and the card
    borderRadius: 3
  };

  if (loading) {
    return (
      <ThemeProvider theme={customTheme}>
        <Box sx={containerStyle}>
          <Card sx={cardStyle}>
            <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <CircularProgress color="secondary" />
              <Typography variant="h5" sx={{ mt: 2 }}>
                載入中，請稍候...
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </ThemeProvider>
    );
  }

  if (result) {
    const randomSignal = [
      "出現三連消後出現三甲",
      "五轉內補分超過壓注五倍",
      "單消寶石降低一半購入免遊",
      "兩連消出現倍數球加兩甲蟲"
    ][Math.floor(Math.random() * 4)];

    return (
      <ThemeProvider theme={customTheme}>
        <Box sx={containerStyle}>
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{ color: 'white', fontFamily: 'Roboto, sans-serif', fontWeight: '800' }} // 設置字體顏色和字體
          >
            電子爆分優化系統
          </Typography>
          <Typography
            variant="caption"
            align="center"
            gutterBottom
            sx={{ color: 'white', fontFamily: 'Roboto, sans-serif', fontWeight: '700' }} // 設置字體顏色和字體
          >
            v1.14.8
          </Typography>
          <Card sx={cardStyle}>
            <CardContent>
              <Typography variant="h5" align="center" gutterBottom>
                運行中...
              </Typography>
              <LinearProgress color="secondary" />
              <Typography variant="h6">
                目前提升機率：
                <Box component="span" sx={{ fontSize: '2rem', color: 'red', ml: 1 }}>
                  {result.percentage}%
                </Box>
              </Typography>
              <Typography variant="body1" gutterBottom>
                使用帳號：{result.account}
              </Typography>
              <Typography variant="body1" gutterBottom>
                使用IP：{result.ipAddress}
              </Typography>
              <Typography variant="body1" gutterBottom>
                使用序號：{result.phoneSerial}
              </Typography>
              <Typography variant="h6" gutterBottom>
                <Box component="span" sx={{ fontSize: '1.8rem', color: '#FDDA0D', mr: 1 }}>
                  建議遊戲方式
                </Box>
                下注金額以遊戲為主
              </Typography>
              <Typography variant="body1" gutterBottom>
                進場金額：{result.amount}
              </Typography>
              <Typography variant="body1" gutterBottom>
                前50轉: {result.formerSpin}
              </Typography>
              <Typography variant="body1" gutterBottom>
                後50轉: {result.postSpin}
              </Typography>
              <Typography variant="body1" gutterBottom>
                最後100轉: {result.lastSpin}
              </Typography>
              <Typography variant="h6" gutterBottom>
                <Box component="span" sx={{ fontSize: '1.8rem', color: '#FDDA0D' }}>
                  建議遊戲方式
                </Box>
              </Typography>
              <Typography variant="body1" gutterBottom>
                訊號：{randomSignal}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center', // 將按鈕置於中間
                  width: '100%',
                  pt: 4,
                  pb: 4
                }}
              >
                <Button variant="contained" 
                  sx={{ 
                    width: '100%', 
                    mt: 2, 
                    borderRadius: 15, 
                    mr: 5 
                  }}
                >
                  IP位置重整
                </Button>
                <Button variant="contained" 
                  sx={{ 
                    width: '100%', 
                    mt: 2, 
                    borderRadius: 15, 
                  }}
                >
                  結束運行
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </ThemeProvider>
    );
  }

  // Form UI
  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={containerStyle}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ color: 'white', fontFamily: 'Roboto, sans-serif', fontWeight: '800' }} // 設置字體顏色和字體
        >
          電子爆分優化系統
        </Typography>
        <Typography
          variant="caption"
          align="center"
          gutterBottom
          sx={{ color: 'white', fontFamily: 'Roboto, sans-serif', fontWeight: '700' }} // 設置字體顏色和字體
        >
          v1.14.8
        </Typography>
        <Card sx={cardStyle}>
          <CardContent>
            <Box
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                label="娛樂城網址"
                variant="outlined"
                name="entertainmentURL"
                value={formData.entertainmentURL}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                label="娛樂城帳號"
                variant="outlined"
                name="account"
                value={formData.account}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                label="手機序號"
                variant="outlined"
                name="phoneSerial"
                value={formData.phoneSerial}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                label="IP位置"
                variant="outlined"
                name="ipAddress"
                value={formData.ipAddress}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                label="電子廠商網址"
                variant="outlined"
                name="vendorURL"
                value={formData.vendorURL}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                label="進場金額"
                variant="outlined"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                fullWidth
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <TextField
                  label="未開免遊次數"
                  variant="outlined"
                  name="unopenedFreeCount"
                  value={formData.unopenedFreeCount}
                  onChange={handleChange}
                  fullWidth
                  sx={{ mr: 1 }} // Add margin to the right
                />
                <TextField
                  label="前一"
                  variant="outlined"
                  name="previousOne"
                  value={formData.previousOne}
                  onChange={handleChange}
                  fullWidth
                  sx={{ mx: 1 }} // Add margin to the left and right
                />
                <TextField
                  label="前二"
                  variant="outlined"
                  name="previousTwo"
                  value={formData.previousTwo}
                  onChange={handleChange}
                  fullWidth
                  sx={{ ml: 1 }} // Add margin to the left
                />
              </Box>
              <TextField
                label="近30日下注金額"
                variant="outlined"
                name="days30"
                value={formData.days30}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                label="得分率*"
                variant="outlined"
                name="pointRate"
                value={formData.pointRate}
                onChange={handleChange}
                fullWidth
              />

              <Button
                variant="contained"
                onClick={handleSubmit}
                padding
                sx={{ width: '100%', mt: 2, borderRadius: 15 }}
              >
                運行
              </Button>
            </Box>
            {error && (
              <Typography color="error" align="center" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                pt: 4,
                pb: 4
              }}
            >
              <Link sx={{ color: 'white' }}>運行日誌</Link>
              <Link sx={{ color: 'white' }}>追蹤日誌</Link>
              <Link sx={{ color: 'white' }}>產品金鑰</Link>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </ThemeProvider>
  );
}