import { 
    CarInput,
    BusinessInput,
    RedMotoInput,
    YellowMotoInput
} from '../theme/plateStyle';

const PlateMockup = ({type, plateNum}) => {

    plateNum = plateNum.toUpperCase()

    const plateInput = (() => {
        switch (type) {
            case 'pv': 
                return (
                    <CarInput 
                        placeholder="AAA-0001"
                        value={plateNum}
                        id="private-vehicle-input"
                    />
                )
            case 'ohm': 
                return (
                    <CarInput 
                        placeholder="AAA-0001"
                        value={plateNum}
                        id="ordinary-heavy-moto-input"
                    />
                )
            case 'ov':
                return (
                    <BusinessInput 
                        placeholder="TAA-0001" 
                        value={plateNum}
                        id="operating-vehicle-input" 
                    />
                )
            case 'rhm': 
                return (
                    <RedMotoInput 
                        placeholder="LGA-0001" 
                        value={plateNum}
                        id="bigsized-heavy-moto-red-input" 
                    />
                )
            case 'yhm': 
                return (
                    <YellowMotoInput
                        placeholder="LAA-0001" 
                        value={plateNum}
                        id="bigsized-heavy-moto-yellow-input" 
                    />
                )
            default:
                return 'Unknown'
        }
    })()

    return (
        <>
            {plateInput}
        </>
    )
}

export default PlateMockup