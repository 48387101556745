import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/header';
import { useNavigate } from "react-router-dom";
import { getUserIp } from '../actions/userActions';

const TestIpScreen = () => {

    const dispatch = useDispatch()
    const userIpDetails = useSelector((state) => state.userIp)
    const { loading, error, data } = userIpDetails

    useEffect(() => {
        dispatch(getUserIp())
    }, [])

    useEffect(() => {
        if(data) {
            console.log(data)
            console.log(data.reqHeaders)
        }
    }, [data])

    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div>
                        <pre>{JSON.stringify(data, null, 4)}</pre>
                    </div>
                </>
            )}
        </>
    )
}

export default TestIpScreen