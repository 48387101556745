import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const PlateReactionDashboard = ({like, treasures, turtle, monkey}) => {
    return (
        <Grid 
            container 
            display={'flex'} 
            alignItems={'center'}
            textAlign={'center'}
        >
            <Grid item xs={3}>
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Typography variant="h6" pr={1}>
                        👍
                    </Typography>
                    <Typography variant="body">
                        {like}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Typography variant="h6" pr={1}>
                        🍗
                    </Typography>
                    <Typography variant="body">
                        {treasures}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Typography variant="h6" pr={1}>
                        🐢
                    </Typography>
                    <Typography variant="body">
                        {turtle}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Typography variant="h6" pr={1}>
                        🐒
                    </Typography>
                    <Typography variant="body">
                        {monkey}
                    </Typography>
                </Box>
            </Grid>
            {(like === 0 && treasures === 0 && turtle === 0 && monkey === 0) && (
                <Grid item xs={12} pt={3}>
                    <Typography variant="caption">
                        是塊乾淨的車牌，做出你的回應！
                    </Typography>
                </Grid>
            )}
        </Grid>
    )
}

export default PlateReactionDashboard