import Typography from '@mui/material/Typography';

const PlateTitle = ({type}) => {

    const title = (() => {
        switch (type) {
            case 'pv': 
                return '普通小客車'
            case 'ohm': 
                return '普通重型機車'
            case 'ov':
                return '營業小客車'
            case 'rhm': 
                return '大型重型機車'
            case 'yhm': 
                return '大型重型機車'
            default:
                return 'Unknown'
        }
    })()

    return (
        <Typography variant="h5" fontWeight={500}>
            {title}
        </Typography>
    )
}

export default PlateTitle