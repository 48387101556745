import {
    FORM_SUBMIT_REQUEST,
    FORM_SUBMIT_SUCCESS,
    FORM_SUBMIT_FAIL
} from '../constants/scamConstants';

export const scamReducer = (state = {}, action) => {
    switch (action.type) {
        case FORM_SUBMIT_REQUEST:
            return { loading: true }
        case FORM_SUBMIT_SUCCESS:
            return { loading: false, success: true }
        case FORM_SUBMIT_FAIL:
            return { loading: false, success: true }
        default:
            return state
    }
};


