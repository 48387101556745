import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Header from '../components/header';
import FullScreenLoader from '../components/FullScreenLoader';
import HistoryReactionList from '../components/HistoryReactionList';
import { signOut } from "firebase/auth";
import auth from '../firebaseConfig';
import { useNavigate } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFireauthToken } from '../utils/fireauth';
import { getUserHistory, getUserProfile } from '../actions/userActions';

const ProfileScreen = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userProfile = useSelector((state) => state.userProfile)
    const userHistory = useSelector((state) => state.userHistory)
    const { profileLoading, profileError, profile } = userProfile
    const { historyLoading, historyError, history } = userHistory
    const [user, loading, error] = useAuthState(auth)
    const [username, setUsername] = useState("")
    const [historyArr, setHistoryArr] = useState([])

    const handleGetToken = async() => {
        const token = await getFireauthToken()
        console.log(token)
    }

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            console.log('Sign-out')
            navigate('/')
        }).catch((error) => {
            // An error happened.
        });
    }

    useEffect(() => {
        if (user && !loading && !profile) {
            dispatch(getUserProfile())
            dispatch(getUserHistory())
            console.log('Get user profile')
        } else if (!user && !loading) {
            navigate('/login')
        }
    }, [loading])

    useEffect(() => {
        if (profile) {
            // handleGetToken()
            setUsername(profile.username)
        } else if (profileError) {
            navigate('/register')
        }
    }, [profile, profileError])

    useEffect(() => {
        console.log(historyLoading)
        if (history) {
            setHistoryArr(history)
        } else if (historyError) {
            // Show history error UI
            console.log('history error')
        }
    }, [history, historyError, historyLoading])

    if (profileLoading || historyLoading) {
        return (
            <>
                <Header arrow={true}/>
                <FullScreenLoader />
            </>
        )
    }

    return (
        <>
            <Header />
            <Grid
                container
                spacing={3}
                direction="column"
                justifyContent="center"
                alignItems={"center"}
                sx={{ minHeight: '100vh' }}
            >   
                <Grid 
                    item 
                    xs={12} 
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    <Avatar sx={{ 
                        bgcolor: 'secondary.main',
                        width: '25vw',
                        height: '25vw'
                    }}>
                        {username[0]}
                    </Avatar>
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    display={'flex'}
                >
                    <TextField
                        id="input-with-icon-textfield"
                        label="使用者名稱"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" variant='sizeSmall'>
                                    <AlternateEmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                        value={username}
                        disabled={true}
                        sx={{width: '55vw'}}
                    />
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    <Button 
                        variant="contained" 
                        onClick={handleLogout}
                        sx={{ width: '75vw'}}
                    >
                        修改使用者名稱
                    </Button>
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    <Button 
                        variant="contained" 
                        onClick={handleLogout}
                        sx={{ width: '75vw'}}
                    >
                        登出
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center", width: '100vw' }}
                >
                    <HistoryReactionList />
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    <Button 
                        variant="contained" 
                        onClick={handleGetToken}
                        sx={{ width: '75vw'}}
                    >
                        Get token
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default ProfileScreen