import { 
    PLATE_DETAILS_REQUEST,
    PLATE_DETAILS_SUCCESS,
    PLATE_DETAILS_FAIL,
    PLATE_REACT_REQUEST,
    PLATE_REACT_SUCCESS,
    PLATE_REACT_FAIL,
    PLATE_COMMENT_REQUEST,
    PLATE_COMMENT_SUCCESS,
    PLATE_COMMENT_FAIL
} from "../constants/plateConstants";

export const plateDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case PLATE_DETAILS_REQUEST:
            return { loading: true }
        case PLATE_DETAILS_SUCCESS:
            return { loading: false, plate: action.payload }
        case PLATE_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const plateReactReducer = (state = {}, action) => {
    switch (action.type) {
        case PLATE_REACT_REQUEST:
            return { reactLoading: true }
        case PLATE_REACT_SUCCESS:
            return { reactLoading: false, reaction: action.payload }
        case PLATE_REACT_FAIL:
            return { reactLoading: false, reactError: action.payload }
        default:
            return state
    }
}

export const plateCommentReducer = (state = {}, action) => {
    switch (action.type) {
        case PLATE_COMMENT_REQUEST:
            return { commentLoading: true }
        case PLATE_COMMENT_SUCCESS:
            return { commentLoading: false, comment: action.payload }
        case PLATE_COMMENT_FAIL:
            return { commentLoading: false, reactError: action.payload }
        default:
            return state
    }
}