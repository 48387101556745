import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import Header from '../components/header';
import PlateInputSwiper from '../components/PlateInputSwiper';
import { Divider } from '@mui/material';
  
const HomeScreen = () => {

    const [tabValue, setTabValue] = useState(0)
    const [plate, setPlate] = useState('')
    const [plateFormatError, setPlateFormatError] = useState(false)
    const navigate = useNavigate()
    const carTypeArr = ['pv', 'ohm', 'ov', 'rhm', 'yhm']

    const checkPlateFormat = () => {
        switch (tabValue) {
            case 0:
                const privateVehiclePlateRe1 = /^([A-Z]{2})-(\d{4})$/g.test(plate)
                const privateVehiclePlateRe2 = /^(\d{4})-([A-Z]{2})$/g.test(plate)
                const privateVehiclePlateRe3 = /^([A-Z]{3})-(\d{4})$/g.test(plate)
                const privateVehiclePlateRe4 = /^([A-Z][0-9])-(\d{4})$/g.test(plate)
                const privateVehiclePlateRe5 = /^([0-9][A-Z])-(\d{4})$/g.test(plate)
                const privateVehiclePlateRe6 = /^(\d{4})-([A-Z][0-9])$/g.test(plate)
                const privateVehiclePlateRe7 = /^(\d{4})-([0-9][A-Z])$/g.test(plate)
                const privateVehiclePlateReTest = ( 
                    privateVehiclePlateRe1 || 
                    privateVehiclePlateRe2 || 
                    privateVehiclePlateRe3 || 
                    privateVehiclePlateRe4 || 
                    privateVehiclePlateRe5 || 
                    privateVehiclePlateRe6 || 
                    privateVehiclePlateRe7 
                )
                const privateVehicleExclusivePlate = !/^[LT]/.test(plate);
                return (privateVehiclePlateReTest && privateVehicleExclusivePlate)
            case 1:
                const ordinaryHeavyMotoPlateRe1 = /^([A-Z]{3})-(\d{3})$/g.test(plate)
                const ordinaryHeavyMotoPlateRe2 = /^(\d{3})-([A-Z]{3})$/g.test(plate)
                const ordinaryHeavyMotoPlateRe3 = /^([A-Z]{3})-(\d{4})$/g.test(plate)
                const ordinaryHeavyMotoExclusivePlate = !/^[LT]/.test(plate);
                return ((ordinaryHeavyMotoPlateRe1 || ordinaryHeavyMotoPlateRe2 || ordinaryHeavyMotoPlateRe3) && ordinaryHeavyMotoExclusivePlate)
            case 2:
                const operatingVehiclePlateRe1 = /^(T)([A-Z]{2})-(\d{3})$/g.test(plate)
                const operatingVehiclePlateRe2 = /^(T)([A-Z]{2})-(\d{4})$/g.test(plate)
                return (operatingVehiclePlateRe1 || operatingVehiclePlateRe2)
            case 3: 
                const bigSizedHeavyMotoRedPlateRe1 = /^([A-Z]{2})-(\d{2})$/g.test(plate)
                const bigSizedHeavyMotoRedPlateRe2 = /^([A-Z]{2})-(\d{3})$/g.test(plate)
                const bigSizedHeavyMotoRedPlateRe3 = /^(L)([G-M][A-Z])-(\d{4})$/g.test(plate)
                const bigSizedHeavyMotoRedExclusivePlate = !/^[T]/.test(plate);
                return ((bigSizedHeavyMotoRedPlateRe1 || bigSizedHeavyMotoRedPlateRe2 || bigSizedHeavyMotoRedPlateRe3) && bigSizedHeavyMotoRedExclusivePlate)
            case 4:
                const bigSizedHeavyMotoYellowPlateRe1 = /^(\d{3})-([A-Z]{3})$/g.test(plate)
                const bigSizedHeavyMotoYellowPlateRe2 = /^(L)([A-Z]{2})-(\d{4})$/g.test(plate)
                const bigSizedHeavyMotoYellowExclusivePlate = !/^[T]/.test(plate);
                return ((bigSizedHeavyMotoYellowPlateRe1 || bigSizedHeavyMotoYellowPlateRe2) && bigSizedHeavyMotoYellowExclusivePlate)
        }
    }

    const handleSearch = () => {
        // Check plate format using re
        const checkResult = checkPlateFormat()
        if (!checkResult) {
            setPlateFormatError(true)
        } else {
            const type = carTypeArr[tabValue]
            navigate(`/${type}/${plate.toLowerCase()}`)
        }
    }

    const ReactionCard = () => {
        return (
            <Grid
                container
                spacing={2}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                px={2}
            >   
                <Grid item xs={6} >
                    <Card sx={{ maxWidth: 345 }}>
                        <Box    
                            sx={{
                                backgroundColor: "primary.main",
                                height: '15vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography gutterBottom variant="h3" component="div" sx={{marginBottom: '0px'}}>
                                😇
                            </Typography>
                        </Box>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                讚
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                形容禮貌駕駛 
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                讚賞美麗的交通工具 
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} >
                    <Card sx={{ maxWidth: 345 }}>
                        <Box    
                            sx={{
                                backgroundColor: "primary.main",
                                height: '15vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Typography gutterBottom variant="h3" component="div" sx={{marginBottom: '0px'}}>
                                🍗
                            </Typography>
                        </Box>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                三寶 
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                不守交通規矩的交通工具駕駛
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} >
                    <Card sx={{ maxWidth: 345 }}>
                        <Box    
                            sx={{
                                backgroundColor: "primary.main",
                                height: '15vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Typography gutterBottom variant="h3" component="div" sx={{marginBottom: '0px'}}>
                                🐢
                            </Typography>
                        </Box>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                烏龜
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                一般泛指開車在做白日夢或長期規在超車道的交通工具駕駛
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} >
                    <Card sx={{ maxWidth: 345 }}>
                        <Box    
                            sx={{
                                backgroundColor: "primary.main",
                                height: '15vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Typography gutterBottom variant="h3" component="div" sx={{marginBottom: '0px'}}>
                                🐒
                            </Typography>
                        </Box>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                猴子
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                年齡已經達到一定水準卻心智、行為退化
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }

    const SloganCard = () => {
        return (
            <Grid
                container
                spacing={2}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                px={2}
            >   
                <Grid item xs={12} >
                    <Card sx={{ display: 'flex' }} >
                        <Box    
                            sx={{
                                backgroundColor: "primary.main",
                                width: '30vw',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <SearchIcon fontSize={'large'} sx={{color: 'white'}}/>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'start'}}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h6">
                                    1. 查詢
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    輸入車牌
                                </Typography>
                            </CardContent>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} >
                    <Card sx={{ display: 'flex' }}>
                        <Box    
                            sx={{
                                backgroundColor: "primary.main",
                                width: '30vw',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <StarHalfIcon fontSize={'large'} sx={{color: 'white'}}/>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'start'}}>
                            <CardContent>
                                <Typography component="div" variant="h6">
                                    2. 評價
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    按下按鈕
                                </Typography>
                            </CardContent>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} >
                    <Card sx={{ display: 'flex' }}>
                        <Box    
                            sx={{
                                backgroundColor: "primary.main",
                                width: '30vw',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <ChatBubbleOutlineIcon fontSize={'large'} sx={{color: 'white'}}/>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'start'}}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h6">
                                    3. 留言
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    留下你想給車主的話 
                                </Typography>
                            </CardContent>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        )
    }

    const Slogan = () => {
        return (
            <Grid
                container
                spacing={5}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                px={2}
            >   
                <Grid 
                    item 
                    xs={4} 
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <Avatar sx={{ 
                        bgcolor: "primary.main",
                        padding: "5vw"
                    }}>
                        <SearchIcon fontSize={'large'} sx={{color: 'white'}}/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} >
                    <Typography component="div" variant="h6">
                        1. 查詢
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        輸入車牌
                    </Typography>
                </Grid>
                <Grid 
                    item 
                    xs={4} 
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <Avatar sx={{ 
                        bgcolor: "primary.main",
                        padding: "5vw"
                    }}>
                        <SentimentSatisfiedAltIcon fontSize={'large'} sx={{color: 'white'}}/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} >
                    <Typography component="div" variant="h6">
                        2. 評價
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        按下按鈕
                    </Typography>
                </Grid>
                <Grid 
                    item 
                    xs={4} 
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <Avatar sx={{ 
                        bgcolor: "primary.main",
                        padding: "5vw"
                    }}>
                        <ChatBubbleOutlineIcon fontSize={'large'} sx={{color: 'white'}}/>
                    </Avatar>
                </Grid>
                <Grid item xs={8} >
                    <Typography component="div" variant="h6">
                        3. 留言
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        留下你想給車主的話
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <Header arrow={false}/>
            <Grid
                container
                spacing={0}
                pt={7}
                justifyContent={'center'}
                justifyItems={'center'}
            >
                <Grid
                    item
                    xs={12}
                >
                    <PlateInputSwiper 
                        tabValue={tabValue}
                        setTabValue={setTabValue}
                        plate={plate} 
                        setPlate={setPlate}
                        error={plateFormatError}
                        handleSearch={handleSearch}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    pb={3}
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    <Button 
                        variant="contained" 
                        startIcon={<SearchIcon />}
                        onClick={handleSearch}
                        sx={{
                            width: '80vw',
                        }}
                    >
                        搜尋
                    </Button>
                </Grid>  
                <Divider/>
                {/* <Grid
                    item
                    p={2}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                    <Typography variant="h4">
                        回應
                    </Typography>
                </Grid> */}
                {/* <Grid
                    item
                    px={2}
                    pb={5}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                    <ReactionCard />
                </Grid> */}
                <Grid
                    item
                    xs={12}
                    p={2}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                    <Typography variant="h4">
                        步驟
                    </Typography>
                </Grid>
                {/* <Grid
                    item
                    xs={12}
                    p={2}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                    <SloganCard />
                </Grid> */}
                <Grid
                    item
                    xs={12}
                    p={2}
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                    <Slogan />
                </Grid>
                <Grid
                    item
                    xs={12}
                    p={2}
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    <Typography variant="caption">
                        &#169;whoscar | 純屬娛樂 
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default HomeScreen