import axios from 'axios'
import { 
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_PROFILE_REQUEST,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAIL,
    USER_HISTORY_REQUEST,
    USER_HISTORY_SUCCESS,
    USER_HISTORY_FAIL,
    USER_IP_REQUEST,
    USER_IP_SUCCESS,
    USER_IP_FAIL
 } from "../constants/userConstants"
 import { getFireauthToken } from "../utils/fireauth"
 import {
    BASE_URL
 } from "../constants/config"
 import _ from "lodash";

export const login = () => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST,
        })
        
        const token = await getFireauthToken()

        // Set the header
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }

        const { data } = await axios.get(`${BASE_URL}/api/users/firsttime`, config)

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const register = (uid, username, loginType) => async (dispatch) => {
    try {
        dispatch({
            type: USER_REGISTER_REQUEST,
        })
        
        const token = await getFireauthToken()

        // Set the header
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }

        const body = {
            uid: uid,
            username: username,
            loginType: loginType
        }

        const { data } = await axios.post(`${BASE_URL}/api/users/register`, body, config)
        console.log("Get data", data)

        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: error.response.data
        })
    }
}

export const getUserProfile = () => async (dispatch) => {
    try {
        dispatch({
            type: USER_PROFILE_REQUEST,
        })
        
        const token = await getFireauthToken()

        // Set the header
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }

        const { data } = await axios.get(`${BASE_URL}/api/users/profile`, config)

        dispatch({
            type: USER_PROFILE_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: USER_PROFILE_FAIL,
            payload: error.response.data
        })
    }
}

export const getUserHistory = () => async (dispatch) => {
    try {
        dispatch({
            type: USER_HISTORY_REQUEST,
        })
        
        const token = await getFireauthToken()

        // Set the header
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }

        const { data } = await axios.get(`${BASE_URL}/api/users/history`, config)

        var historyArr = data.selfComment.concat(data.selfReaction)

        _.sortBy(historyArr, 'timestamp', 'desc');

        // console.log(historyArr)

        dispatch({
            type: USER_HISTORY_SUCCESS,
            payload: historyArr,
        })
    } catch (error) {
        dispatch({
            type: USER_HISTORY_FAIL,
            payload: error.response.data
        })
    }
}

export const getUserIp = () => async (dispatch) => {
    try {
        dispatch({
            type: USER_IP_REQUEST,
        })

        const { data } = await axios.get(`${BASE_URL}/test`)
        // console.log(data)

        dispatch({
            type: USER_IP_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: USER_IP_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}