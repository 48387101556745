import React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import moment from 'moment';
import 'moment/locale/zh-tw';

const mockUpComments = [
    {
        plateNum: 'aza-6996',
        plateType: 'pv',
        content: 'comment1',
        timestamp: '1698037611'
    },
    {
        plateNum: 'taa-0001',
        plateType: 'pv',
        type: '👍',
        timestamp: '1698037611'
    },
    {
        plateNum: '2489-sj',
        plateType: 'pv',
        type: '👍',
        content: 'test',
        timestamp: '1698037611'
    },
]

const HistoryReactionList = ({historys}) => {

    const commentLists = mockUpComments.map((comment) => 
        <>
            <ListItem 
                alignItems="flex-start"
            >
                <ListItemAvatar>
                    <Avatar sx={{ 
                        bgcolor: 'secondary.main',
                        width: '12vw',
                        height: '12vw',
                        marginRight: '10px'
                    }}>
                        {comment.type === "comment" ? (
                           <InsertCommentIcon />
                        ) : (
                            comment.type
                        )}    
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={comment.plateNum}
                    secondary={
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body"
                                    color="text.primary"
                                >
                                    {comment.content}
                                </Typography>
                            </Grid>
                            <Grid xs={12}>
                                <Typography
                                    component="span"
                                    variant="caption"
                                    color="text.secondary"
                                >
                                    {moment(comment.timestamp*1000).fromNow()}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                />
            </ListItem>
            <Divider variant="middle" component="li" />
        </>
    )

    const reservedCommentListItem = () => {
        return (
            <ListItem 
                alignItems="flex-start"
                secondaryAction={
                    <>
                        <IconButton 
                            edge="end" 
                            aria-label="comments" 
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <ArrowUpwardIcon />
                            <Typography
                                sx={{ display: 'inline' }}
                                variant="button"
                            >
                                3
                            </Typography>
                        </IconButton>
                    </>
                }
            >
                <ListItemText
                    primary="aza-6996"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body"
                                color="text.primary"
                            >
                                {"I'll be in your neighborhood doing errands this…"}
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
        )
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
        >
            <Grid
                item
                pt={4}
                sx={{ display: "flex", justifyContent: "flex-start" }}
            >
                <Typography variant="h5" fontWeight={500}>
                    歷史紀錄
                </Typography>
            </Grid>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Divider />   
                {mockUpComments.length === 0 ? (
                    <Typography
                        variant="h6"
                        textAlign={'center'}
                        pt={3}
                    >
                        成為第一個留言的人
                    </Typography> 
                ) : (
                    commentLists
                )}
            </List>     
        </Grid>
    )
}

export default HistoryReactionList