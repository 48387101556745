import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#606D5D',
        },
        secondary: {
            main: '#D1603D',
        },
        error: {
            main: '#d20528',
        },
    },
    shape: {
        borderRadius: 10,
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'loginButton' },
                    style: {
                        backgroundColor: 'primary',
                        width: '70vw'
                    },
                },
            ],
        },
    },
});

export default theme;