export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST'
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS'
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL'
export const USER_HISTORY_REQUEST = 'USER_HISTORY_REQUEST'
export const USER_HISTORY_SUCCESS = 'USER_HISTORY_SUCCESS'
export const USER_HISTORY_FAIL = 'USER_HISTORY_FAIL'
export const USER_IP_REQUEST = 'USER_IP_REQUEST'
export const USER_IP_SUCCESS = 'USER_IP_SUCCESS'
export const USER_IP_FAIL = 'USER_IP_FAIL'
