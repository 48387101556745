import React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import moment from 'moment';
import 'moment/locale/zh-tw';

const mockUpComments = [
    {
        username: 'an6us',
        content: '紅線違停'
    },
    {
        username: 'yqoopy',
        content: '邪惡霹靂貓'
    },
    {
        username: 'ad3lyn',
        content: '講公路違停，林老師勒'
    },
]

const PlateCommentList = ({comments}) => {

    const commentLists = comments.map((comment) => 
        <>
            <ListItem 
                alignItems="flex-start"
            >
                <ListItemText
                    primary={comment.fromUsername}
                    secondary={
                        <Grid container>
                            <Grid item xs={12}>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body"
                                color="text.primary"
                            >
                                {comment.content}
                            </Typography>
                            </Grid>
                            <Grid xs={12}>
                            <Typography
                                component="span"
                                variant="caption"
                                color="text.secondary"
                            >
                                {moment(comment.timestamp*1000).fromNow()}
                            </Typography>
                            </Grid>
                        </Grid>
                    }
                />
            </ListItem>
            <Divider variant="middle" component="li" />
        </>
    )

    const reservedCommentListItem = () => {
        return (
            <ListItem 
                alignItems="flex-start"
                secondaryAction={
                    <>
                        <IconButton 
                            edge="end" 
                            aria-label="comments" 
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <ArrowUpwardIcon />
                            <Typography
                                sx={{ display: 'inline' }}
                                variant="button"
                            >
                                3
                            </Typography>
                        </IconButton>
                    </>
                }
            >
                <ListItemText
                    primary="an6us"
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body"
                                color="text.primary"
                            >
                                {"I'll be in your neighborhood doing errands this…"}
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
        )
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
        >
            <Grid
                item
                pt={4}
                sx={{ display: "flex", justifyContent: "flex-start" }}
            >
                <Typography variant="h5" fontWeight={500}>
                    留言  {comments.length}
                </Typography>
            </Grid>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Divider />   
                {comments.length === 0 ? (
                    <Typography
                        variant="h6"
                        textAlign={'center'}
                        pt={3}
                    >
                        成為第一個留言的人
                    </Typography> 
                ) : (
                    commentLists
                )}
            </List>     
        </Grid>
    )
}

export default PlateCommentList