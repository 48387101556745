import { 
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_PROFILE_REQUEST,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAIL,
    USER_HISTORY_REQUEST,
    USER_HISTORY_SUCCESS,
    USER_HISTORY_FAIL,
    USER_IP_REQUEST,
    USER_IP_FAIL,
    USER_IP_SUCCESS
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loginLoading: true }
        case USER_LOGIN_SUCCESS:
            return { loginLoading: false, userInfo: action.payload }
        case USER_LOGIN_FAIL:
            return { loginLoading: false, error: action.payload }
        case USER_LOGOUT:
            return {}
        default:
            return state
    }
}

export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { registerLoading: true }
        case USER_REGISTER_SUCCESS:
            return { registerLoading: false, userInfo: action.payload }
        case USER_REGISTER_FAIL:
            return { registerLoading: false, registerError: action.payload }
        default:
            return state
    }
}

export const userProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_PROFILE_REQUEST:
            return { profileLoading: true }
        case USER_PROFILE_SUCCESS:
            return { profileLoading: false, profile: action.payload }
        case USER_PROFILE_FAIL:
            return { profileLoading: false, profileError: action.payload }
        default:
            return state
    }
}

export const userHistoryReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_HISTORY_REQUEST:
            return { historyLoading: true }
        case USER_HISTORY_SUCCESS:
            return { historyLoading: false, history: action.payload }
        case USER_HISTORY_FAIL:
            return { historyLoading: false, historyError: action.payload }
        default:
            return state
    }
}

export const userIpReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_IP_REQUEST:
            return { loading: true }
        case USER_IP_SUCCESS:
            return { loading: false, data: action.payload }
        case USER_IP_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}