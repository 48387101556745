import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';

const CarInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-root": {
        width: '80vw',
    },
    ".Mui-error > .MuiInputBase-input": {
        border: '1px solid #ff0000',
        borderColor: 'red',
    },
    "& .MuiFormHelperText-root": {
        color: 'gray'
    },
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '.MuiInputBase-input': {
        borderRadius: 8,
        position: 'relative',
        backgroundColor: '#FFFFFF',
        border: '1px solid #ced4da',
        fontSize: '15vw',
        fontWeight: 'bold',
        width: '80vw',
        padding: '8px 1px',
        textAlign: 'center',
        transform: "scaleX(0.75)",
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `#D3D3D3 0 0 0 0.2rem`,
            borderColor: 'black',
        }
    },
}));

const NewCarInput = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        width: '80vw',
    },
    ".Mui-error > .MuiInputBase-input": {
        border: '1px solid #ff0000',
        borderColor: 'red',
    },
    "& .MuiFormHelperText-root": {
        color: 'gray'
    },
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '.MuiInputBase-input': {
        borderRadius: 8,
        position: 'relative',
        backgroundColor: '#FFFFFF',
        // border: '1px solid #ced4da',
        fontSize: '15vw',
        fontWeight: 'bold',
        width: '80vw',
        padding: '8px 1px',
        textAlign: 'center',
        transform: "scaleX(0.75)",
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `#D3D3D3 0 0 0 0.2rem`,
            borderColor: 'black',
        }
    },
}));

const BusinessInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 8,
        position: 'relative',
        backgroundColor: '#FFFFFF',
        border: '1px solid #ced4da',
        fontSize: '15vw',
        fontWeight: 'bold',
        width: '80vw',
        padding: '8px 1px',
        color: 'red',
        textAlign: 'center',
        transform: "scaleX(0.75)",
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `#D3D3D3 0 0 0 0.2rem`,
            borderColor: 'black',
        },
    },
}));

const RedMotoInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 8,
        position: 'relative',
        backgroundColor: 'red',
        border: '1px solid #ced4da',
        color: 'white',
        fontSize: '15vw',
        fontWeight: 'bold',
        width: '80vw',
        padding: '8px 1px',
        textAlign: 'center',
        transform: "scaleX(0.75)",
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `#D3D3D3 0 0 0 0.2rem`,
            borderColor: 'black',
        },
    },
}));

const YellowMotoInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 8,
        position: 'relative',
        backgroundColor: 'yellow',
        border: '1px solid #ced4da',
        color: 'black',
        fontSize: '15vw',
        fontWeight: 'bold',
        width: '80vw',
        padding: '8px 1px',
        textAlign: 'center',
        transform: "scaleX(0.75)",
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `#D3D3D3 0 0 0 0.2rem`,
            borderColor: 'black',
        },
    },
}));

export {
    CarInput, 
    NewCarInput,
    BusinessInput,
    RedMotoInput,
    YellowMotoInput
}